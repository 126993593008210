<template>
  <Member
    title="Dr. Francesco De Cobelli"
    description="Primario Unità Operativa di Radioterapia e Radiodiagnostica"
    text="Primario U.O. Radiologia, Professore Ordinario di Radiologia, Direttore Scuola di Specializzazione di Radiodiagnostica, Direttore del corso di dottorato Internazionale di Medicina Clinica e Sperimentale presso l’Università Vita-Salute San Raffaele, Responsabile dell’unità di ricerca in Radiologia Clinica e Sperimentale del Centro di Imaging Sperimentale.

Dopo la laurea in Medicina e Chirurgia presso l’università degli Studi di Milano nel 1991, si specializza in Radiodiagnostica nel 1995 presso il medesimo istituto. Durante il periodo Universitario si forma anche negli Stati Uniti come fellow. La sua attività clinica si avvia nel 1996 come assistente ospedaliero e successivamente come dirigente di I° livello presso il Servizio di Radiologia dell’IRCCS Ospedale San Raffaele di Milano. Diviene successivamente coordinatore di Area per l’Attività scientifica e didattica del Servizio di Radiologia del medesimo istituto e dal 2007 diviene dirigente responsabile dell’Unità Funzionale di Risonanza Magnetica dell’Unità Operativa di Radiologia. Attualmente è Principal Investigator o Co-PI di numerosi trial promossi dall’unità o promossi da strutture internazionali sui temi di radiologia interventistica e di diagnostica per immagini. Relatore invitato in più di 100 incontri nazionali e internazionali, autore di 221 articoli scientifici pubblicati in riviste nazionali ed internazionali (H-Index e di numero di citazioni, fonte Scopus: 39 e 5925), partecipa costantemente a convegni e conferenze del settore, sia nazionali sia internazionali."
    :formation="[
      'Laurea in Medicina e Chirurgia, Università degli Studi di Milano',
      'Specialità in Radiologia, Università degli Studi di Milano',
      'Research Fellow in Physiology, Case Western Reserve University, Cleveland, USA',
      '',
    ]"
    publication=""
    :pub="false"
    :headerImage="localImage"
  />
</template>

<script>
import Member from "../routes/Member";
import teamImage from "@/assets/member-background.jpg";
export default {
  name: "Cobelli",
  components: {
    Member,
  },
  data() {
    return {
      localImage: teamImage,
    };
  },
};
</script>
